import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function Main(){

let  [tokens, setTokens] = useState('')

async function getAccessToken() {
  try {
    const BASE_URL = "https://ipg1.apps.net.pk/Ecommerce/api/Transaction/GetAccessToken";

    const merchantId = 21288;
    const securedKey = "wNQ3VofFZIiDK7Ke4P6J8eO";

    const response = await axios.post(`${BASE_URL}/token`, {
      // BASKET_ID : '',
      // TXNAMT: '',
      //   SECURED_KEY :"wNQ3VofFZIiDK7Ke4P6J8eO",
      // MERCHANT_ID :"21288",
      MERCHANT_ID : merchantId,
      SECURED_KEY : securedKey,
    TXNAMT :5 , 
    BASKET_ID : "2910",
    CURRENCY_CODE: "PKR"
    });

    setTokens(response?.data?.ACCESS_TOKEN)
    console.log(tokens);
 
    console.log(response);
    // alert("Token Fetched Successfully")
    return response;
  } catch (error) {
    console.log(error.message);
    // setRetry(prev => prev + 1); // Increment retry to trigger useEffect
  }
}
   useEffect (()=>{
    getAccessToken()
   },[])


    const queryParamsRestaurantFee = new URLSearchParams(
      {
         paymentAmount: '5',  
         restaurantId:"2910",
         type:'RestuarantFee',
         token:tokens
       }).toString();

      //  paymentAmount same which stored in restaurant fee
  
      
    const   queryParamsFeatured = new URLSearchParams({ 
    
        paymentAmount: '5', 
        restaurantId : '1',
        type:'FeauturedFee' ,
        featureId:'1',
        token:tokens
      }).toString();


      const queryParamsOrderPaymentOnline = new URLSearchParams(
        {
           paymentAmount: '5',  
           restaurantId:"1",
           invoiceId:"1",
           userId:'1',
           type:'OrderPaymentOnline',
           token:tokens
         }).toString();
        
    return(
        <div className="App">
    <h1>

      {/* Hello  */}

    </h1>
    Link to the route with parameters (Restaurant)
    {/* <Link to={`http://localhost:3000/PaymentForm?${queryParamsRestaurantFee}`}>  */}
    <Link to={`http://foodpayment.ashhkaro.com/PaymentForm?${queryParamsRestaurantFee}`}> 
    Go to Your Component with Parameters (RestaurantFee)
    </Link>
    <br/>
    <br/>
    <Link to={`http://foodpayment.ashhkaro.com/PaymentForm?${queryParamsFeatured}`}>
    {/* <Link to={`http://localhost:3000/PaymentForm?${queryParamsFeatured}`}> */}
    Go to Your Component with Parameters (Featured)
    </Link>
    <br/>
    <br/>
    {/* <Link to={`http://localhost:3000/PaymentForm?${queryParamsOrderPaymentOnline}`}> */}
    <Link to={`http://foodpayment.ashhkaro.com/PaymentForm?${queryParamsOrderPaymentOnline}`}>
    Go to Your Component with Parameters (OrderPaymentOnline)
    </Link>

    </div>
    )
}

export default Main;